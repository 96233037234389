<template>
    <!-- 收藏 -->
    <Layout>
        <div class="collection_box">
            <div class="base-width">
                <!-- 面包屑 -->
                <BreadCrumb :list='routes' :icon='false' v-ani.fade-up />
                <div class="cate_box" v-ani.fade-up>
                    <!-- 分类 -->
                    <div class="tabs_box">
                        <div :class="['tabs', 's16', tabsIndex == index ? 'active' : '']" v-for="(item, index) in tabsArr"
                            :key="index" @click="() => tabsIndex = index">
                            {{ item }}
                        </div>
                    </div>
                </div>
                <!-- 收藏的商品 -->
                <CollectGoods v-if="tabsIndex == 0"></CollectGoods>
                <!-- 收藏的店铺 -->
                <collectShop v-if="tabsIndex == 1"></collectShop>
            </div>
        </div>
    </Layout>
</template>
<script>
export default {
    created() {

    },
    data() {
        return {
            routes: [
                { name: '首页', link: '/' },
                { name: '我的收藏', link: '' }
            ],
            tabsArr: ['收藏的商品', '收藏的店铺'],
            tabsIndex: 0,
        }
    },
    methods: {

    }
}
</script>
<style lang="scss" scoped>
.collection_box {
    padding-bottom: .4rem;
}

.cate_box {
    padding: 0.2rem;
    height: 0.8rem;
    background-color: #fff;
    border-radius: .04rem;

    .tabs_box {
        padding-bottom: 0.09rem;
        display: flex;
        border-bottom: 0.02rem solid #ECF5FA;

        .tabs {
            margin-right: 0.15rem;
            cursor: pointer;

            &.active {
                color: $blue;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    left: 0;
                    bottom: -0.09rem;
                    width: 100%;
                    height: 2px;
                    background: $blue;
                }
            }
        }
    }
}
</style>